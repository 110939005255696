<template>
  <div
    aria-live="assertive"
    class="relative flex gap-1 b b-grey-70 bg-white p-2 px-4 text-sm shadow-xs $rounded <md:px-2 "
    data-test-id="vf-notification"
    role="alert"
    style="--rounded: 0.125rem"
    :style="{ 'justify-content': variant }"
  >
    <vf-icon
      v-if="type"
            :class="{
        'c-green-30': type === 'success',
                'c-red-30': type === 'error',
      }"
      :name="type"
      :size="iconSize?.[$viewport.breakpoint]"
    />
    <span
      class="py-2 c-grey-10 "
      :class="{
        'flex-1': !variant,
        'mr-6': variant && dismissible && closeStyle !== 'label',
        'mr-12': variant && dismissible && closeStyle === 'label',
      }"
    >
      <slot />
    </span>
    <base-button
      v-if="(type !== 'error' || dismissibleOnError) && dismissible"
      class="h-8 cursor-pointer "
      :class="{ 'absolute right-0 mr-4 <md:mr-2': variant }"
      data-test-id="vf-notification-close"
      @click="$emit('close')"
    >
      <span :class="closeStyle === 'label' ? 'subtitle-5 underlined' : 'sr-only'">
        {{ $t.dismiss }}
      </span>
      <vf-icon v-if="closeStyle === 'icon'" name="close" :size="closeIconSize?.[$viewport.breakpoint]" />
    </base-button>
  </div>
</template>

<script lang="ts" setup>
import type { BaseNotification } from '#types/notification'

withDefaults(
  defineProps<Omit<BaseNotification, 'message'> & {
    /**
     * Defines content position
     */
    variant?: 'center' | 'left'
    /**
     * Defines close button visibility
     * If true close button becomes visible and emits 'close' event on click
     * Ignored if a type is equal to 'error'
     */
    dismissible?: boolean
  }>(),
  {
    dismissible: true,
    variant: 'left'
  }
)

defineEmits<{
  /**
   * Emits when close button is clicked
   */
  close: []
}>()

const { dismissibleOnError, closeIconSize, closeStyle, iconSize } = useAppConfig().components.vf.notification || {}
</script>

<template>
  <div class="relative flex gap-2 py-4 md:pl-4" :style="theme ? `--theme: ${theme}` : null">
    <figure class="relative shrink-0 <md:hidden">
      <base-picture :alt="image.alt" height="130" :src="image.src" width="130" />
      <figcaption v-if="label" class="absolute right-0 top-0 min-w-8 flex center bg-$theme p-2 text-xs">
        {{ label }}
      </figcaption>
    </figure>
    <div class="w-full flex b-$theme md:flex-col md:b-r <md:items-center">
      <div class="flex grow pr-4 md:flex-col">
        <div class="w-18 shrink-0 items-center gap-1 b-r b-grey-70 pr-2 text-xs md:w-a md:flex md:b-transparent">
          <template v-if="startDate">
            <vf-icon class="<md:hidden" name="calendar" size="xs" style="margin-top: -0.125rem" />
            <p>{{ startDate }}</p>
            <template v-if="endDate && startDate !== endDate">
              <p>-</p>
              <p>{{ endDate }}</p>
            </template>
          </template>
          <p v-else class="uppercase">
            {{ $t.comingSoon }}
          </p>
        </div>
        <div class="f-col grow justify-center <md:pl-2">
          <p class="line-clamp-2 text-sm fw-medium">
            {{ title }}
          </p>
        </div>
      </div>
      <vf-button
        class="md:self-start"
        :size="{ md: 'tiny' }"
        :variant="{ md: 'tertiary' }"
        @click="$emit('show-event')"
      >
        <span class="absolute-0 z-1" />
        <span class="<md:sr-only">{{ $t.viewEvent }}</span>
        <vf-icon class="md:hidden" name="chevron" size="md" />
      </vf-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { EventCard } from '#types/components/vf/event-card'

const props = defineProps<EventCard>()

defineEmits<{ 'show-event': [] }>()

const { $locale } = useNuxtApp()
const startDate = computed(() => props.date?.start ? new Date(props.date.start).toLocaleDateString($locale) : null)
const endDate = computed(() => props.date?.end ? new Date(props.date.end).toLocaleDateString($locale) : null)
</script>

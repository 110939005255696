<template>
  <base-file-upload v-model="files" v-bind="forwarded" @change="onChange">
    <vf-notification v-if="errorMessage" class="mb-4" dismissible type="error">
      {{ errorMessage }}
    </vf-notification>
    <label class="f-col items-start gap-2">
      {{ $t.fileUpload.title }}:
      <base-file-upload-trigger as-child>
        <vf-button size="tiny">
          {{ $t.fileUpload.button }}
        </vf-button>
      </base-file-upload-trigger>
    </label>
    <div class="py-2">
      {{ replaceAll($t.fileUpload.helper, { maxFiles, maxFileSize: `${maxFileSize / 1e6}MB`, types }) }}
    </div>
    <base-file-upload-item
      v-for="file in files"
      :key="file.name"
      class="mt-2 flex items-center px-4 py-2 shadow-xs between"
      :file
    >
      <div>{{ file.name }}</div>
      <base-file-upload-item-delete as-child>
        <base-button>
          <vf-icon name="close" size="lg" />
        </base-button>
      </base-file-upload-item-delete>
    </base-file-upload-item>
  </base-file-upload>
</template>

<script lang="ts" setup>
import { useForwardProps } from 'radix-vue'

type Reason = 'accept' | 'maxFiles' | 'maxFileSize'

const props = withDefaults(defineProps<{
  /**
   * The accept file types
   */
  accept?: string[]
  /**
   * The maximum file size in bytes
   */
  maxFileSize?: number
  /**
   * The maximum number of files
   */
  maxFiles?: number
}>(), {
  accept: () => ['image/jpeg', 'image/gif', 'image/png', 'image/tiff', 'image/bmp', 'application/pdf'],
  maxFileSize: 1e7,
  maxFiles: 5
})

const emit = defineEmits<{
  change: [reason?: Reason]
}>()

const files = defineModel<File[]>()
const forwarded = useForwardProps(props)
const { $t } = useNuxtApp()

const types = props.accept.map((type) => type.split('/')[1].toUpperCase()).join(', ')
const errorMessage = ref()

function onChange(reason?: Reason) {
  errorMessage.value = undefined

  if (reason === 'accept')
    errorMessage.value = replaceAll($t.fileUpload.acceptError, { types })
  if (reason === 'maxFiles')
    errorMessage.value = replaceAll($t.fileUpload.maxFilesError, { maxFiles: props.maxFiles })
  if (reason === 'maxFileSize')
    errorMessage.value = replaceAll($t.fileUpload.maxFileSizeError, { maxFileSize: `${props.maxFileSize / 1e6}MB` })

  emit('change', reason)
}
</script>

<template>
  <div class="relative" data-test-id="vf-video">
    <base-video
      ref="videoRef"
      v-style="directives?.['v-style']"
      class="full cover"
      v-bind="{ ...$props }"
      :controls="false"
      @ended="$emit('ended', $event)"
      @pause="$emit('pause', $event)"
      @play="$emit('play', $event)"
      @playing="$emit('playing', $event)"
    />
    <client-only>
      <vf-video-controls
        v-if="controls"
        class="absolute right-0 z-1 m-4 c-white duration <md:top-0 md:bottom-0 bottom-0 <md:top-a "
        :class="classControls"
        :paused="videoRef?.paused"
        :progress="videoRef?.progress"
        :size="controlsSize || defaultControlSizes"
        :style="styleControls"
        @click="videoRef?.toggle"
      />
    </client-only>
    <slot />
  </div>
</template>

<script lang="ts" setup>
import type { StyleValue } from 'vue'
import type { CSSClass, Responsive } from '#types/common'
import type { BaseVideo as BaseVideoProps } from '#types/components/base/video'
import type { IconSizes } from '#types/sizes'

interface VfVideoProps extends BaseVideoProps {
  controls?: boolean
  classControls?: CSSClass
  controlsSize?: IconSizes | Partial<Responsive<IconSizes>>
  styleControls?: StyleValue
  directives?: Record<string, any>
}

interface VfVideoRef extends HTMLVideoElement {
  paused: boolean
  progress: number
  toggle: () => void
}

withDefaults(defineProps<VfVideoProps>(), {
  controls: true
})

defineEmits<{
  ended: [e: Event]
  pause: [e: Event]
  play: [e: Event]
  playing: [e: Event]
}>()

const { controlSizes: defaultControlSizes } = useAppConfig().components.vf.video

const videoRef = ref<VfVideoRef>()

defineExpose({ videoRef, play: () => videoRef.value?.play(), pause: () => videoRef.value?.pause() })
</script>

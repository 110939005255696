<template>
  <div ref="panelRef" class="fixed-0 z-overlay flex" :class="positions[_position]">
    <base-dialog-root default-open @update:open="$emit('resolve')">
      <base-dialog-overlay
        class="vf-uki69b"
        data-test-id="vf-panel-backdrop"
        :style="[styleBackdrop, hideBackdrop ? '' : 'background: #000000bf']"
      />
      <vf-dialog-content
        class="vf-9hj32y"
        :class="[
          transforms[_position],
          isX
            ? ['h-full', brandClasses.content.x]
            : [
              'w-full',
              { 'h-full': size === 'full' },
              _size === 'lg' ? 'md:w-2/3' : 'lg:w-2/3',
              brandClasses.content.y,
            ],
          classContent,
        ]"
        :style="
          isX
            ? maxPanelSize?.x ? `max-width: ${maxPanelSize.x}` : ''
            : maxPanelSize?.y ? `max-height: ${maxPanelSize.y}` : ''
        "
      >
        <slot />
      </vf-dialog-content>
    </base-dialog-root>
  </div>
</template>

<script lang="ts" setup>
import type { CSSClass, Responsive } from '#types/common'
import type { SizesSubUnion } from '#types/sizes'
import type { DialogBaseOptions } from '#types/config/components/dialog'

type Size = SizesSubUnion<'sm' | 'lg' | 'full'>

export type Props = {
  /**
   * Size of the panel.
   */
  size?: Size | Responsive<Size>
  /**
   * Position of the panel.
   */
  position?: DialogBaseOptions['position']
  /**
   * Classes to be applied to content container.
   */
  classContent?: CSSClass
  /**
   * Classes to be applied to close button.
   */
  classClose?: CSSClass
  styleBackdrop?: string
  /**
   * If backdrop is shown.
   */
  hideBackdrop?: boolean
  /**
   * Whether "close" button is shown.
   */
  hideCloseButton?: boolean
}

const { position, size } = withDefaults(defineProps<Props>(), {
  position: () => ({ sm: 'bottom', lg: 'right' }),
  size: 'full',
})

defineEmits<{
  resolve: []
}>()

const { $viewport } = useNuxtApp()
const { brandClasses, maxPanelSize } = useAppConfig().components.vf.panel

const panelRef = ref()
const _position = computed(() => getValueForBreakpoint($viewport.breakpoint, position)!)
const _size = computed(() => getValueForBreakpoint($viewport.breakpoint, size)!)
const isX = computed(() => ['left', 'right'].includes(_position.value))

const transforms = {
  bottom: '[.nested-enter-from_&,.nested-leave-to_&]:translate-y-20',
  left: '[.nested-enter-from_&,.nested-leave-to_&]:-translate-x-20',
  right: '[.nested-enter-from_&,.nested-leave-to_&]:translate-x-20'
}

const positions = {
  bottom: 'items-end justify-center',
  left: 'items-center justify-start',
  right: 'items-center justify-end'
}
</script>
